import React, { useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/brand/logo-icon-white.svg";
import LogoL from "../../assets/brand/logo-white.svg";
import menuItems from "./menu.json";
import menuItemsBottom from "./menu-bottom.json";
import sideHrLine from "../../assets/images/side-menu-hr-line.svg";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);

  function collapseMenu() {
    const ele = document.getElementById("sideMenubar");
    // Add logic to handle the opening/closing of the sidebar
    // ele.classList.toggle('open');
  }

  function toggleSubmenu(index: number, hasSubMenu: boolean | undefined) {
    if (hasSubMenu) {
      setOpenSubMenu((prevIndex) => (prevIndex === index ? null : index));
    }
  }

  return (
    <div id="sideMenubar" className="sidebar" onClick={collapseMenu}>
      <div className="header-wrapper">
        <div className="brand-logo">
          {/* <img src={Logo} alt="logo" className="logosmall" />
          <img src={LogoL} alt="logo" className="logolarge" /> */}
        </div>
      </div>
      <div>
        <nav>
          <ul className="menus">
            {menuItems.map((menu, index) => {
              const submenuList = menu.submenuList;
              const isActive =
                splitLocation[1] === menu.active ||
                (menu.hasSubMenu && openSubMenu === index);

              return (
                <li
                  className={
                    "menu-items" +
                    (isActive ? " active" : "") +
                    (menu.hasSubMenu ? " has-submenu" : "")
                  }
                  key={index}
                  onClick={() => toggleSubmenu(index, menu.hasSubMenu)}
                >
                  {isActive && <div className="active-indicator"></div>}
                  <Link to={menu.to}>
                    <i className={menu.icon}></i>
                    <span>{menu.name} </span>
                    <span className="activeicons"> </span>
                  </Link>

                  {menu.hasSubMenu && submenuList && (
                    <ul
                      className={
                        "submenu-dropdown" +
                        (openSubMenu === index ? " open" : "")
                      }
                    >
                      {submenuList.map((submenu, subIndex) => (
                        <li
                          className={
                            "menu-items" +
                            (splitLocation[2] === submenu.active
                              ? " active"
                              : "")
                          }
                          key={subIndex}
                        >
                          <Link to={submenu.to}>
                            <span>{submenu.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>

          <ul className="menus bottom ">
            <div className="hr-line-conainer">
              <img src={sideHrLine} alt="hr line" />
            </div>

            {menuItemsBottom.map((menu, index) => {
              const isActive = splitLocation[1] === menu.active;

              return (
                <li
                  className={
                    "menu-items" +
                    (isActive ? " active" : "") +
                    (menu.hasSubMenu ? " has-submenu" : "")
                  }
                  key={index}
                  onClick={() => toggleSubmenu(index, menu.hasSubMenu)}
                >
                  {isActive && <div className="active-indicator"></div>}
                  <Link to={menu.to}>
                    <i className={menu.icon}></i>
                    <span>{menu.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
