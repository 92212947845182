import React, { useEffect, useState } from "react";
import DashboardService from "../../services/DashboardService";

const SyncDataDaily = () => {
    const [showOutDated, setShowOutDated] = useState(false);
    const [isSyncing, setSyncing] = useState(false);

    const checkIsSyncOutDated = async () => {
        let isOutDated = true;
        try {
            const lastSyncInfo = await DashboardService.getLastSyncData();
            if (lastSyncInfo && lastSyncInfo.lastKDPSync) {
                const syncDate = new Date(lastSyncInfo.lastKDPSync);

                // Get the current date
                const currentDate = new Date();

                // Check if lastSyncDate is not today
                isOutDated = syncDate.getDate() !== currentDate.getDate() ||
                    syncDate.getMonth() !== currentDate.getMonth() ||
                    syncDate.getFullYear() !== currentDate.getFullYear();
            }
        }
        catch (error) {
            console.error("Error fetching last sync data:", error);
        }
        setShowOutDated(isOutDated);
        return isOutDated;
    };

    const handleSyncClick = () => {
        setSyncing(true);
        console.log("Syncing daily data via Amazon...");

        const userToken = localStorage.getItem("userToken");
        const url = `https://account.kdp.amazon.com?t=${userToken}`;
        const newWindow = window.open(url, "_blank", "width=800,height=600");

        const syncCheckInterval = setInterval(async () => {
            const isOutDated = await checkIsSyncOutDated();
            if (!isOutDated) {
                cleanupInterval();
            }
        }, 30000); // 1 minute interval

        // Cleanup the interval when setShowOutDated is true
        const cleanupInterval = () => {
            clearInterval(syncCheckInterval);
        };
    };

    useEffect(() => {
        checkIsSyncOutDated();
    }, []);

    return (
        <>
            {
                showOutDated &&
                (
                    <div className="sync-data-daily">
                        <p className="syncmsg" style={{ fontWeight: "600" }}>
                            The current sales data is outdated. Connect your Amazon account for the latest updates.
                        </p>
                        <button
                            className="sync-button"
                            onClick={handleSyncClick}
                            disabled={isSyncing}
                            style={{ fontWeight: "600", fontSize: "14px", lineHeight: "21px" }}
                        >
                            {isSyncing ? "Syncing..." : "Sync Daily Data via Amazon"}
                        </button>
                        <span className="close-button" onClick={() => { setShowOutDated(false); }}>
                            X
                        </span>
                    </div>
                )
            }
        </>

    );
};

export default SyncDataDaily;
