import React, { useEffect, useState } from "react";

function ConditionFilter(props) {
  const [metaData, setMetaData] = useState<any>([]);
  const [isunable, setIsUnable] = useState<any>({
    status: [],
    marketPlace: [],
  });
  const [isinput, setIsInput] = useState<any>([]);
  const updated = {
    columnName: "",
    conditionName: "",
    conditionValue: "",
    conditionbetweenValue: "",
  };
  const [formValues, setFormValues] = useState([
    {
      columnName: "",
      conditionName: "",
      conditionValue: "",
      conditionbetweenValue: "",
    },
  ]);
  const [dropdownData, setDropdownDatas] = useState<any[]>([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    // console.log(newFormValues[i][e.target.value]);
    newFormValues[i][e.target.name] = e.target.value;
    if (e.target.name === "columnName") {
      setSelectedColumn(e.target.value);
    } else if (e.target.name === "conditionName") {
      setSelectedCondition(e.target.value);
    }
    setFormValues(newFormValues);
    if (
      e.target.name === "columnName" &&
      dropdownData[formValues[i].columnName]["name"] === "Status"
    ) {
      const checkStatus = formValues?.map((item, index) => {
        if (item.columnName === e.target.value) {
          return index;
        }
      });
      setIsUnable((prev: any) => {
        return {
          ...prev,
          status: checkStatus.filter((index) => index !== null),
        };
      });
    } else if (
      dropdownData[formValues[i].columnName]["name"] !== "Status" &&
      e.target.name === "columnName"
    ) {
      setIsUnable((prev: any) => ({
        ...prev,
        status: prev.status.filter((_, index) => index !== i),
      }));
      setFormValues((prev: any) => {
        return prev.map((item, index) =>
          index === i ? { ...item, conditionValue: "" } : item
        );
      });
    }
    if (
      e.target.name === "columnName" &&
      dropdownData[formValues[i].columnName]["name"] === "Marketplace"
    ) {
      const checkStatus = formValues?.map((item, index) => {
        if (item.columnName === e.target.value) {
          return index;
        }
      });
      setIsUnable((prev: any) => {
        return {
          ...prev,
          marketPlace: checkStatus.filter((index) => index !== null),
        };
      });
    } else if (
      dropdownData[formValues[i].columnName]["name"] !== "Marketplace" &&
      e.target.name === "columnName"
    ) {
      setIsUnable((prev: any) => ({
        ...prev,
        marketPlace: prev.marketPlace.filter((_, index) => index !== i),
      }));
      setFormValues((prev: any) => {
        return prev.map((item, index) =>
          index === i ? { ...item, conditionValue: "" } : item
        );
      });
    }
  };
  console.log(isunable);

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        columnName: "",
        conditionName: "",
        conditionValue: "",
        conditionbetweenValue: "",
      },
    ]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  const handleSubmit = (event) => {
    let filterArray: any = [];
    for (let i = 0; formValues.length > i; i++) {
      let getColumnName = dropdownData[formValues[i].columnName]["name"];
      console.log(getColumnName);

      let filterCondition = {
        logicalOperator: "And",
        conditions: [
          {
            logicalOperator: "And",
            operator: formValues[i].conditionName,
            value: formValues[i].conditionValue,
            secondValue: formValues[i].conditionbetweenValue || null,
            columnName: getColumnName,
          },
        ],
      };
      filterArray.push(filterCondition);
    }
    props.parentCallback(filterArray);
    cancelDropdown();
    event.preventDefault();
  };
  const refreshHandler = () => {
    setIsUnable({ status: [], marketPlace: [] });
    setFormValues([updated]);
    let filterArray: any = [];
    for (let i = 0; formValues.length > i; i++) {
      let filterCondition = {
        logicalOperator: "And",
        conditions: [],
      };
      filterArray.push(filterCondition);
    }
    props.parentCallback(filterArray);
  };
  useEffect(() => {
    console.log("Meta data - condition filter: ", props.metaData);
    setMetaData(props.metaData);
  }, [props.metaData]);

  useEffect(() => {
    if (props.dropdownData.length > 0) {
      console.log("Condition dropdown option data: ", props.dropdownData);
      buildConditionDropdownData(props.dropdownData, props.metaData);
    }
  }, [props.dropdownData, dropdownData]);

  const buildConditionDropdownData = (listData, metaDataList) => {
    let result = metaDataList;
    if (listData && result) {
      for (let i = 0; i < listData.length; i++) {
        listData[i]["id"] = i + 1;
        listData[i]["selected"] = false;
        let slectedConditionType = listData[i]["filterConditionType"];
        let conditionTypeList = result[slectedConditionType];
        listData[i]["conditionTypes"] = conditionTypeList;
      }
      setDropdownDatas(listData);
    }
  };

  const SubSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.columnName) {
      let conditionData = dropdownData[eleData.columnName];
      let optionData = conditionData.conditionTypes;
      if (optionData && optionData.length > 0) {
        return optionData.map((options, i) => (
          <option key={i} value={options.value}>
            {options.name}
          </option>
        ));
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const cancelDropdown = () => {
    const eleRm: any = document.getElementsByClassName("dropdown-menu");
    if (eleRm) {
      if (eleRm.length > 0) {
        for (let i = 0; i < eleRm.length; i++) {
          eleRm[i].classList.remove("show");
        }
      }
    }
  };

  const statusDropdownDataValues = [
    {
      name: "Enabled",
      value: "ENABLED",
    },
    {
      name: "Paused",
      value: "PAUSED",
    },
    {
      name: "Archived",
      value: "ARCHIVED",
    },
  ];
  const marketPlaceData = props?.metaData?.marketplaces?.map((item, index) => {
    return (
      <option key={index} value={item.value}>
        {item.name}
      </option>
    );
  });
  const betweenValuesHandler = (i, e) => {
    const betweenOperator = e.target.value;
    console.log(betweenOperator);
    if (betweenOperator === "Between" || betweenOperator === "IsBetween") {
      const checkStatus = formValues.map((item, index) => {
        if (item.conditionName === "Between" || item.conditionName === "IsBetween") {
          return index;
        }
        return null;
      });
      setIsInput(checkStatus.filter((index) => index !== null));
    } else {
      setIsInput((prev) => {
        return prev.filter((item, index) => index !== i);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <table className="condition-filter">
        <tbody>
          {formValues.map((element, index) => (
            <tr key={index}>
              <td>
                <select
                  style={{
                    marginTop:
                      selectedColumn === "impression" &&
                      selectedCondition === "Between Two Values"
                        ? "-55px"
                        : "0",
                  }}
                  className="form-select width-184"
                  name="columnName"
                  value={element.columnName || ""}
                  onChange={(e) => {
                    handleChange(index, e);
                    // handleStatusHandler(e);
                  }}
                >
                  <option value="">--Select--</option>
                  {dropdownData.map((list, i) => (
                    <option value={i} id={list.displayName} key={i}>
                      {list.displayName}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  style={{
                    marginTop:
                      selectedColumn === "impression" &&
                      selectedCondition === "Between Two Values"
                        ? "-55px"
                        : "0",
                  }}
                  className="form-select width-150"
                  name="conditionName"
                  value={element.conditionName || ""}
                  onChange={(e) => {
                    handleChange(index, e);
                    betweenValuesHandler(index, e);
                  }}
                >
                  <option value="">--Select--</option>
                  <SubSelectOptions index={index} eleData={element} />
                </select>
              </td>
              <td>
                {isunable?.status?.includes(index) ? (
                  <div>
                    <select
                      className="form-select width-219"
                      name="conditionValue"
                      value={element.conditionValue || ""}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                    >
                      <option value="">--Select--</option>
                      {statusDropdownDataValues.map((list, i) => (
                        <option value={list.value} key={i}>
                          {list.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <>
                    {isinput.includes(index) ? (
                      <>
                        <div className="inbetween-inputfiles">
                          <div>
                            <input
                              type="text"
                              placeholder="Value"
                              name="conditionValue"
                              className="form-control width-100"
                              value={element.conditionValue || ""}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                          <div className="text-center">
                            <p>-</p>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Second-Value"
                              name="conditionbetweenValue"
                              className="form-control width-100"
                              value={element.conditionbetweenValue || ""}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {!isunable?.marketPlace?.includes(index) ? (
                          <div>
                            <input
                              type="text"
                              placeholder="Value"
                              name="conditionValue"
                              className="form-control width-219"
                              value={element.conditionValue || ""}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                        ) : (
                          <div>
                            <select
                              className="form-select width-219"
                              name="conditionValue"
                              value={element.conditionValue || ""}
                              onChange={(e) => handleChange(index, e)}
                            >
                              <option value="">--Select--</option>
                              {marketPlaceData}
                            </select>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </td>
              <td>
                {index ? (
                  <button
                    type="button"
                    className="btn btn-box"
                    onClick={() => removeFormFields(index)}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button
          type="button"
          className="btn btn-link add-more-btn"
          onClick={() => addFormFields()}
        >
          <i className="circle-add-icon" /> Add New Filter
        </button>
      </div>
      <hr />
      <div className="footer">
        <button
          type="button"
          className="btn btn-default"
          onClick={() => {
            cancelDropdown();
            refreshHandler();
          }}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          Apply
        </button>
      </div>
    </form>
  );
}

export default ConditionFilter;
