import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import VisaLogo from "../../assets/images/visa-logo.png";
import ProCrown from "../../assets/images/proCrown.png";
import CrownIcon from "../../assets/images/icons/crownLogo.svg";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import { log } from "console";
import { loadStripe, Stripe, StripeCardElement } from "@stripe/stripe-js";
import { ok } from "assert";
import BillingTable from "../MyAccount/Table/billingTable";
import "./index.css";
const stripePromise = loadStripe(
  "pk_test_51OAaShKWuE6hwS1cCS6n9dyFPZSDbvhUZ9jHX9Zxhw60d322GmTzGYphq2BGskikE06WrNcdtqZAuu1KFlHcnm6E00Ne1g8XXD"
);

interface CardDetails {
  name: string;
  expMonth: number;
  expYear: number;
  rememberMe: boolean;
}

const PlanExpriy: React.FunctionComponent = () => {
  const [customerplandetails, Setcustomerplan] = useState<any>([]);
  const [planstatus, SetplanStatus] = useState<string>("freeplanfalse");

  const [proplandetails, Setproplan] = useState<any>([]);

  const [monthlyplandetails, SetMonthlyplan] = useState<any>([]);
  const [yearlyplandetails, SetYearlyplan] = useState<any>([]);
  const [chosenplandetails, Setchosenplan] = useState<any>([]);
  const [successpay, Setsuccesspay] = useState<any>(false);
  const [editCard, SeteditCard] = useState<any>(null);
  const [addCards, SetaddCard] = useState<any>(false);
  const [showcardelement, Setshowcardelement] = useState<any>(false);
  const [customerCardDetail, Setcustomercarddetail] = useState<any>([]);

  const [card, setCard] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const stripeRef = useRef<Stripe | null>(null);
  const [cardElement, setCardElement] = useState<StripeCardElement | null>(
    null
  );
  const cardElementRef = useRef<HTMLDivElement | null>(null);

  const [cardDetails, setCardDetails] = useState<CardDetails>({
    name: "",
    expMonth: 0,
    expYear: 0,
    rememberMe: false,
  });

  useEffect(() => {
    fetchPlan();
    GetCardDetails();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setCardDetails({
      ...cardDetails,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  let userToken = localStorage.getItem("userToken");
  let AuthToken = "Bearer " + userToken;

  const fetchStripeData = async () => {
    try {
      const stripe = await stripePromise;
      stripeRef.current = stripe;
      if (stripe) {
        const elements = stripe.elements();
        const card = elements.create("card");
        setCardElement(card);
        card.mount("#card-element");
      } else {
        console.error("Stripe elements not available.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditCard = async (cardid) => {
    SeteditCard(cardid);
  };
  const handleAddCardclose = async () => {
    SeteditCard(false);
    Setshowcardelement(false);
  };
  const handleAddCard = async () => {
    SetaddCard(true);
    Setshowcardelement(true);
    fetchStripeData();
  };
  const handlePayment = async () => {
    try {
      const stripe = stripeRef.current;
      if (!stripe || !cardElement) {
        console.error("card element is not found");
        return;
      }

      const result = await stripe.createToken(cardElement);
      if (result.error) {
        setErrorMessage(result.error.message || "");
      } else {
        const token = result.token;
        if (token) {
          console.log("token id :" + token.id);
          if (chosenplandetails.length > 1) {
            await processPayment(token.id, token.card?.id);
          }
          if (addCards === true) {
            await addCard(token.id);
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const processPayment = async (tokenId, cardtoken) => {
    const planid = chosenplandetails.map((item) => item.id);
    try {
      const response = await fetch(
        "https://api.aimosa.io/PaymentGateway/Stripe/Payment/Creation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            planId: planid[0],
            token: tokenId,
            cardToken: cardtoken,
            featureId: "",
            recurringPayment: false,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseJson = await response.json();
      Setsuccesspay(true);
      console.log(responseJson);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchPlan = async () => {
    try {
      const response = await fetch("https://api.aimosa.io/Plan", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        let customerplan = localStorage.getItem("planName");
        const customerPlan = responseData.result.find(
          (item: any) => item.name.toLowerCase() === "freeplan"
        );
        if (customerPlan) {
          console.log(customerPlan);
          if (customerPlan.name.toLowerCase() === "freeplan") {
            if (customerPlan.planStatus === true) {
              // SetplanStatus("freeplantrue");
            } else {
              // SetplanStatus("freeplanfalse");
            }
          } else if (
            customerPlan.name.toLowerCase() === "monthly" ||
            customerPlan.name.toLowerCase() === "yearly"
          ) {
            if (customerPlan.planStatus === true) {
              // SetplanStatus("proplantrue");
            } else {
              // SetplanStatus("proplanfalse");
            }
          }
          Setcustomerplan([customerPlan]);
        }
        const promonthlyPlan = responseData.result.find(
          (item: any) => item.name.toLowerCase() === "monthlyplan"
        );
        if (promonthlyPlan) {
          console.log(promonthlyPlan);
          SetMonthlyplan([promonthlyPlan]);
        }
        const proyearlyPlan = responseData.result.find(
          (item: any) => item.name.toLowerCase() === "yearlyplan"
        );
        if (proyearlyPlan) {
          console.log(proyearlyPlan);
          SetYearlyplan([proyearlyPlan]);
        }
      } else {
        throw new Error("Failed to fetch");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    Setsuccesspay(false);
  };

  const GetCardDetails = async () => {
    try {
      const response = await fetch(
        "https://api.aimosa.io/PaymentGateway/Stripe/Card/Get",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        console.log("Card Details:", JSON.stringify(responseData));
        console.log("Card Details:" + responseData.card);

        Setcustomercarddetail(responseData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateCard = async (cardid) => {
    try {
      const response = await fetch(
        "https://api.aimosa.io/PaymentGateway/Stripe/card",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            cardId: cardid,
            expMonth: cardDetails.expMonth,
            expYear: cardDetails.expYear,
            name: cardDetails.name,
          }),
        }
      );
      if (response.ok) {
        toast("My card has been changed successfully");
      } else {
        toast("Error updating default card");
      }
    } catch (error) {}
  };

  const DaleteCard = async (cardid) => {
    try {
      const response = await fetch(
        "https://api.aimosa.io/PaymentGateway/Stripe/Card",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            cardId: cardid,
          }),
        }
      );
      if (response.ok) {
        toast("My card has been Deleted successfully");
      } else {
        toast("Error Deleteing default card");
      }
    } catch (error) {}
  };

  const addCard = async (cardId) => {
    try {
      const response = await fetch(
        "https://api.aimosa.io/PaymentGateway/Stripe/card/Add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            cardToken: cardId,
          }),
        }
      );
      if (response.ok) {
        toast("My card has been changed successfully");
      } else {
        toast("Error updating default card");
      }
    } catch (error) {}
  };
  const selectPlan = (eve, item) => {
    const id = JSON.stringify(item);
    console.log(id);
    Setchosenplan([item]);

    const selectedPlan: any = document.querySelectorAll(".plan-container");
    if (selectedPlan.length > 0) {
      const clearEle: any = document.querySelectorAll(
        ".plan-container.selected"
      );
      for (var i = 0; i < clearEle.length; i++) {
        clearEle[i].classList.remove("selected");
      }
      for (let i = 0; i < selectedPlan.length; i++) {
        if (selectedPlan[i].contains(eve.target)) {
          selectedPlan[i].classList.add("selected");
          fetchStripeData();
        }
      }
    }
  };
  return (
    <>
      <div className="container-fluid  my-account-billing">
        <div className="row">
          <Col md={12}>
            <div className="container billingCard">
              <div className="Billingheading">
                <h2>Billing & Plan</h2>
              </div>
              {/* free */}

              {customerplandetails.map((item, index) => {
                console.log("plan statud :" + planstatus);
                if (planstatus === "freeplantrue") {
                  return (
                    <>
                      <Row className="freePlanHeading">
                        <Col md={9} className="freePlanContainer">
                          <h4>{item.name}</h4>
                          <h3>
                            ${item.price.amount}
                            <small>/{item.price.priceType}</small>
                          </h3>
                          <p className="upgradePlanAlink">
                            {item.description}{" "}
                            <a
                              data-bs-toggle="modal"
                              href="#exampleModalToggle"
                              role="button"
                            >
                              Upgrade Plan
                            </a>{" "}
                            to access all Aimosa features.
                          </p>
                        </Col>
                        <Col md={3}>
                          <div className="upgradePlanBtn">
                            <button>
                              <a
                                data-bs-toggle="modal"
                                href="#exampleModalToggle"
                                role="button"
                              >
                                Upgrade Plan
                              </a>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                } else if (planstatus === "freeplanfalse") {
                  return (
                    <>
                      <Row
                        className="freePlanHeading"
                        style={{
                          backgroundColor: "#FFF9F2",
                          borderColor: "red",
                        }}
                      >
                        <Col md={9} className="freePlanContainer">
                          <h4>{item.name}</h4>
                          <h3>
                            ${item.price.amount}
                            <small>/{item.price.priceType}</small>
                          </h3>
                          <p className="upgradePlanAlink">
                            <p style={{ color: "red", marginRight: "10px" }}>
                              Amazing achievement! Your royalties have exceeded
                              $500 in the last 30 days.
                              <span>
                                Upgrade now for full access to Aimosa's powerful
                                features!
                              </span>
                            </p>
                          </p>
                        </Col>
                        <Col md={3}>
                          <div className="upgradePlanBtn">
                            <button>
                              <a
                                data-bs-toggle="modal"
                                href="#exampleModalToggle"
                                role="button"
                              >
                                Upgrade Plan
                              </a>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                } else if (planstatus === "proplantrue") {
                  return (
                    <>
                      <Row className="proVersionHeader">
                        <Col md={10} className="proVersionContainer">
                          <div className="proVersionContent">
                            <img src={CrownIcon} alt="logo" width={30} />
                            <h4>PRO Version</h4>
                          </div>
                          <h3>
                            ${item.price.amount}
                            <small>/{item.price.priceType}</small>{" "}
                          </h3>
                          <p>
                            Your next payment is ${item.price.amount} on{" "}
                            {item.offerEndDate}. Your payment will be
                            automatically renewed each month.*VAT & local taxes
                            may apply.{" "}
                          </p>
                        </Col>
                        <Col md={2}>
                          <div className="cancelRenewalBtn">
                            <h6>Cancel Renewal</h6>
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                } else if (planstatus === "proplanfalse") {
                  return (
                    <>
                      <Row
                        className="proVersionHeader"
                        style={{
                          backgroundColor: "#FFF9F2",
                          borderColor: "red",
                        }}
                      >
                        <Col md={10} className="proVersionContainer">
                          <div className="proVersionContent">
                            <img src={CrownIcon} alt="logo" width={30} />
                            <h4>PRO Version</h4>
                          </div>
                          <h3>
                            ${item.price.amount}
                            <small>/{item.price.priceType}</small>{" "}
                          </h3>
                          <p className="upgradePlanAlink">
                            <p style={{ color: "red", marginRight: "10px" }}>
                              Upgrade now for full access to Aimosa's powerful
                              features!
                            </p>
                          </p>
                        </Col>
                        <Col md={2}>
                          <div className="upgradePlanBtn">
                            <button>
                              <a
                                data-bs-toggle="modal"
                                href="#exampleModalToggle"
                                role="button"
                              >
                                Upgrade Plan
                              </a>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }
              })}

              {/* pro */}

              {/* payment */}

              {/* <div className="paymentheading">
                <h2>Payment Methods</h2>
                <button className="addCardButton" onClick={handleAddCard} >Add Card  </button>
               {showcardelement && (
<>
<h3>card details</h3>
<Row className="saveCardHeading">
                <div className="cardDetails">
                  
                  <div id="card-element" ref={cardElementRef} style={{ border: '1px solid #ccc', padding: '10px' }} />
                  <div id="card-errors" role="alert">{errorMessage}</div>
                </div>
                <div className="payContent">
                  <button onClick={handlePayment}>Add Card</button><br></br>
                  <button onClick={handleAddCardclose}>cancel</button>
                </div>
              </Row>
</>
               )}
              </div> */}
              {/* {customerCardDetail.map((item, index) => {
                     return (
               <Row className="paymentContainer mb-3">
                 <Col md={9} className="visaContent">
                   <div className="visaLogo">
                     <img src={VisaLogo} alt="visalogo" width={70} />
                   </div>
                   <h6>{item.name}</h6>
                   <h6>**********{item.last4}</h6>
                 </Col>
                 <Col md={3}>
                   <div className="dropdownContent">
                     
                     <p
                       id="dropdownMenuButton1"
                       data-bs-toggle="dropdown"
                       aria-expanded="false"
                     >
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                        width="16"
                         height="16"
                         fill="currentColor"
                         className="bi bi-three-dots-vertical"
                         viewBox="0 0 16 16"
                       >
                         <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                       </svg>
                     </p>
                     <ul
                       className="dropdown-menu"
                       aria-labelledby={`dropdownMenuButton${index}`}
                     >
                       <li>
                         <p
                           className="dropdown-item"
                           data-bs-toggle="modal"
                           data-bs-target="#staticBackdrop"
                           onClick={() => handleEditCard(item.id)}
                         >
                           Edit card
                         </p>

                       </li>
                       <li>
                         <p className="dropdown-item text-danger" onClick={() => DaleteCard(item.id)}>Delete</p>
                       </li>
                     </ul>
                   </div>
                   <div className="expireContent">
                     <h6>Card expires at {item.expMonth}/{item.expYear}</h6>
                   </div>
                 </Col>
               </Row>
                )
               })} */}
              {/* Billing History */}
              <Row className="billingHistory">
                <Col md={8}>
                  {" "}
                  <h2>Billing History</h2>
                </Col>
                <Col md={4}>
                  <Col md={12} className="searchBox">
                    <div className="form-input">
                      <span className="icon">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </span>
                      <input
                        type="email"
                        name="email"
                        placeholder="Search"
                        className="form-control"
                      />
                    </div>
                  </Col>
                </Col>
              </Row>

              <BillingTable />
            </div>
          </Col>
        </div>

        {/* upgrade model */}
        <div
          className="modal fade"
          id="exampleModalToggle"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content upgradeModel">
              <div className="modal-body upgradeModelBody">
                <Row>
                  <Col md={12}>
                    <h3 className="upgrade-plan-text">
                      Upgrade to PRO Version
                    </h3>
                    <p className="upgrade-para">
                      Upgrade to our Pro Version and unlock all the exciting
                      features.
                    </p>
                  </Col>
                </Row>
                <Row>
                  {monthlyplandetails.map((item, index) => {
                    return (
                      <Col md={6}>
                        <div
                          className="card plan-container"
                          onClick={(e) => {
                            selectPlan(e, item);
                          }}
                        >
                          <i className="plan-status-icon" />

                          <h3>{item.name}</h3>
                          <h2>
                            ${item.price.amount}
                            <small>/{item.price.priceType}</small>
                          </h2>
                          <p className="billed-text">{item.description}</p>
                        </div>
                      </Col>
                    );
                  })}

                  {yearlyplandetails.map((item, index) => {
                    return (
                      <Col md={6}>
                        <div
                          className="card plan-container"
                          onClick={(e) => {
                            selectPlan(e, item);
                          }}
                        >
                          <i className="plan-status-icon" />
                          <h3>{item.name}</h3>
                          <div className="discount">
                            <h2>
                              ${item.price.amount}
                              <small>/{item.price.priceType}</small>
                            </h2>
                            <span>Save up to $20</span>
                          </div>
                          <p className="billed-text">{item.description}</p>
                        </div>
                      </Col>
                    );
                  })}
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btnforCancel"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn selectedPayNowBtn payNow-btn "
                          data-bs-target="#exampleModalToggle2"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                        >
                          Pay Now
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* payment model */}
        <div
          className="modal fade"
          id="exampleModalToggle2"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel2"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content paymentModel">
              <div className="closeIcon">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {chosenplandetails.map((item, index) => {
                return (
                  <Row className="saveCardHeading">
                    <div className="cardDetails">
                      <div
                        id="card-element"
                        ref={cardElementRef}
                        style={{ border: "1px solid #ccc", padding: "10px" }}
                      />
                      <div id="card-errors" role="alert">
                        {errorMessage}
                      </div>
                    </div>
                    <div className="payContent">
                      <button onClick={handlePayment}>
                        Pay ${item.price.amount}
                      </button>
                    </div>
                  </Row>
                );
              })}
            </div>
          </div>
        </div>
        {/* proCrown model */}
        {successpay && (
          <div
            className="modal fade show"
            id="exampleModalToggle3"
            style={{ display: "block" }}
            aria-labelledby="exampleModalToggleLabel3"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content proCrownModel">
                <Row className="proCrownImageContainer">
                  <Col md={12}>
                    <div className="proCrownImg">
                      <img src={ProCrown} alt="Crown image" height={150} />
                    </div>
                    <Row className="proCrownHeading">
                      <h4>Successfully Upgrade to PRO Version</h4>
                      <p>
                        Congratulations! You have successfully upgraded your
                        account to <b>PRO Version</b>. Enjoy unlimited services
                        from us right now.
                      </p>
                    </Row>
                  </Col>
                  <div className="proCrownOkbtnContainer">
                    <button
                      type="button"
                      className="crownOkBtn"
                      // data-bs-dismiss="modal"
                      // aria-label="Close"
                      onClick={closeModal}
                    >
                      ok
                    </button>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        )}

        {/* model for card edit */}
        {editCard !== null && (
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content editCardModel">
                <div className="closeIcon">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Row className="editCardHeading">
                  <Col md={12} className="editCardForm">
                    <h5>Edit My Card</h5>
                    <div className="form-input">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        value={cardDetails.name}
                        onChange={handleInputChange}
                      />
                      <span className="icon">
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="MM"
                        name="expMonth"
                        value={cardDetails.expMonth}
                        onChange={handleInputChange}
                      />
                      <span>/</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="YY"
                        name="expYear"
                        value={cardDetails.expYear}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="readMe">
                      <input
                        type="checkbox"
                        name="rememberMe"
                        checked={cardDetails.rememberMe}
                        onChange={handleInputChange}
                      />
                      <label>Remember me</label>
                    </div>
                    <button
                      type="button"
                      className="btn saveCard saveCardBtn"
                      data-bs-dismiss="modal"
                      onClick={() => UpdateCard(editCard)}
                    >
                      Save Card
                    </button>
                    <button onClick={() => SeteditCard(false)}>Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
    // new
  );
};
export default PlanExpriy;
