/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import LogoIcon from "../../../../../assets/brand/logo-icon.svg";
import SwitchIcon from "../../../../../assets/images/icons/switch-icon.svg";
import DuplicateIcon from "../../../../../assets/images/icons/duplicate-icon.svg";
import RemoveIcon from "../../../../../assets/images/icons/trash-icon.svg";
import moveIcon from "../../../../../assets/images/icons/sort-move-icon.svg";
import { toast } from "react-toastify";

function AddRulesThen(props) {
  const [ruleDataLoading, setRuleDataLoading] = useState(true);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [metaData, setMetaData] = useState([]);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [actionUpToCriteriaDetails, setActionUpToCriteriaDetails] = useState(
    []
  );
  const [targetAndActionDetails, setTargetAndActionDetails] = useState([
    {
      name: "Ad Group",
      title: "Ad Group",
      subTitle: "",
      description: "Ad Group",
      childValues: [
        {
          name: "Change Default Bid",
          type: "ActionBy",
          visible: true,
          order: 2,
          displayName: "Change Default Bid",
          filterConditionType: "numeric",
          possibleValues: ["Increase", "Decrease", "Set"],
          target: null,
          datatype: "double",
          units: ["$", "%"],
        },
        {
          name: "Change Status",
          type: "List",
          visible: true,
          order: 4,
          displayName: "Change Status",
          filterConditionType: "text",
          possibleValues: ["Enabled", "Paused", "Archive"],
          target: [
            {
              name: "Target",
              applyTo: null,
              to: null,
            },
            {
              name: "Ad Group",
              applyTo: null,
              to: null,
            },
            {
              name: "Campaign",
              applyTo: null,
              to: null,
            },
          ],
          datatype: null,
          units: null,
        },
      ],
    },
    {
      name: "Search Term",
      title: "Search Term",
      subTitle: "",
      description: "Search Term",
      childValues: [
        {
          name: "Change Bid",
          type: "ActionBy",
          visible: true,
          order: 1,
          displayName: "Change Bid",
          filterConditionType: "numeric",
          possibleValues: ["Increase", "Decrease", "Set"],
          target: null,
          datatype: "double",
          units: ["$", "%"],
        },
        {
          name: "Change Placements %",
          type: "ActionTo",
          visible: true,
          order: 3,
          displayName: "Change Placements %",
          filterConditionType: "numeric",
          possibleValues: ["Increase", "Decrease", "Set"],
          target: [
            {
              name: "Top of search (first page)",
              applyTo: null,
              to: null,
            },
            {
              name: "Product pages",
              applyTo: null,
              to: null,
            },
          ],
          datatype: "double",
          units: ["%"],
        },
        {
          name: "Change Status",
          type: "List",
          visible: true,
          order: 4,
          displayName: "Change Status",
          filterConditionType: "text",
          possibleValues: ["Enabled", "Paused", "Archive"],
          target: [
            {
              name: "Target",
              applyTo: null,
              to: null,
            },
            {
              name: "Ad Group",
              applyTo: null,
              to: null,
            },
            {
              name: "Campaign",
              applyTo: null,
              to: null,
            },
          ],
          datatype: null,
          units: null,
        },
        {
          name: "Negative Targets",
          type: "ActionSet",
          visible: true,
          order: 5,
          displayName: "Negative Targets",
          filterConditionType: "text",
          possibleValues: [
            "Negative Exact",
            "Negative Phrase",
            "Individual products (ASIN)",
          ],
          target: [
            {
              name: "Negative Keywords",
              applyTo: ["Ad Group level", "Campaign level"],
              to: null,
            },
            {
              name: "Negative Products",
              applyTo: ["Ad Group level", "Campaign level"],
              to: null,
            },
          ],
          datatype: null,
          units: null,
        },
        {
          name: "Change Bidding Strategy",
          type: "ActionChangeTo",
          visible: true,
          order: 6,
          displayName: "Change Bidding Strategy",
          filterConditionType: "text",
          possibleValues: ["Down Only", "Up & Down", "Fixed"],
          target: null,
          datatype: null,
          units: [],
        },
      ],
    },
    {
      name: "Target",
      title: "Target",
      subTitle: "",
      description: "Target",
      childValues: [
        {
          name: "Change Bid",
          type: "ActionBy",
          visible: true,
          order: 1,
          displayName: "Change Bid",
          filterConditionType: "numeric",
          possibleValues: ["Increase", "Decrease", "Set"],
          target: null,
          datatype: "double",
          units: ["$", "%"],
        },
        {
          name: "Change Placements %",
          type: "ActionTo",
          visible: true,
          order: 3,
          displayName: "Change Placements %",
          filterConditionType: "numeric",
          possibleValues: ["Increase", "Decrease", "Set"],
          target: [
            {
              name: "Top of search (first page)",
              applyTo: null,
              to: null,
            },
            {
              name: "Product pages",
              applyTo: null,
              to: null,
            },
          ],
          datatype: "double",
          units: ["%"],
        },
        {
          name: "Change Status",
          type: "List",
          visible: true,
          order: 4,
          displayName: "Change Status",
          filterConditionType: "text",
          possibleValues: ["Enabled", "Paused", "Archive"],
          target: [
            {
              name: "Target",
              applyTo: null,
              to: null,
            },
            {
              name: "Ad Group",
              applyTo: null,
              to: null,
            },
            {
              name: "Campaign",
              applyTo: null,
              to: null,
            },
          ],
          datatype: null,
          units: null,
        },
        {
          name: "Negative Targets",
          type: "ActionSet",
          visible: true,
          order: 5,
          displayName: "Negative Targets",
          filterConditionType: "text",
          possibleValues: [
            "Negative Exact",
            "Negative Phrase",
            "Individual products (ASIN)",
          ],
          target: [
            {
              name: "Negative Keywords",
              applyTo: ["Ad Group level", "Campaign level"],
              to: null,
            },
            {
              name: "Negative Products",
              applyTo: ["Ad Group level", "Campaign level"],
              to: null,
            },
          ],
          datatype: null,
          units: null,
        },
        {
          name: "Change Bidding Strategy",
          type: "ActionChangeTo",
          visible: true,
          order: 6,
          displayName: "Change Bidding Strategy",
          filterConditionType: "text",
          possibleValues: ["Down Only", "Up & Down", "Fixed"],
          target: null,
          datatype: null,
          units: [],
        },
      ],
    },
  ]);

  const [newActionUpToCriteriaDetails, setNewActionUpToCriteriaDetails] =
    useState([]);
  const [formValues, setFormValues] = useState([
    {
      conditionFilter: "",
      conditionName: "",
      conditionValue: "",
      target: "",
      unitName: "",
      switch: "List",
      conditionNameOperand: "",
    },
  ]);
  console.log(formValues);
  useEffect(() => {
    if (props.rulesMetaData) {
      let actionUpToCriteriaDetails = props.rulesMetaData.actionCriteriaDetails;
      if (actionUpToCriteriaDetails !== undefined) {
        setRulesMetaData(props.rulesMetaData);
        setActionUpToCriteriaDetails(props.rulesMetaData.actionCriteriaDetails);
        const checkValidation = props.wizardData.ifWizard
          .map((item, index) => item.target)
          .toString();
        if (checkValidation === "Ad Group") {
          const updateArray = props.rulesMetaData.actionCriteriaDetails.filter(
            (item) =>
              item?.displayName === "Change Status" ||
              item?.displayName === "Change Default Bid"||
              item?.displayName === "Change Placements %"||
              item?.displayName === "Change Bidding Strategy"

          );
          console.log(updateArray);
          setNewActionUpToCriteriaDetails(updateArray);
        } else {
          setNewActionUpToCriteriaDetails(
            props.rulesMetaData.actionCriteriaDetails
          );
        }
        setRuleDataLoading(false);
      }
    }
    setMetaData(props.metaData);
  }, [props.rulesMetaData, rulesMetaData, props.metaData, metaData]);

  useEffect(() => {
    if (props.metaData && props.rulesMetaData.actionCriteriaDetails) {
      if (props.metaData.text) {
        // buildConditionDropdownData(props.rulesMetaData.actionCriteriaDetails.filters, props.metaData);
        setMetaDataLoading(false);
      }
    }
  }, [props.rulesMetaData, props.metaData]);

  useEffect(() => {
    if (props.wizardData.thenWizard?.length > 0) {
      let actionCriteriaList = [];
      let actionCriteriaData = props.wizardData.thenWizard;
      console.log(actionCriteriaData);
      for (let i = 0; i < actionCriteriaData?.length; i++) {
        if (actionCriteriaData[i].expressions.operand) {
          let obj = {
            conditionFilter: actionCriteriaData[i].expressions.operand,
            conditionName: actionCriteriaData[i].expressions.operator,
            target: actionCriteriaData[i].target.name,
            conditionValue:
              actionCriteriaData[i].expressions.value[0].inputExpression.input,
            unitName: actionCriteriaData[i].expressions.unit,
            switch: actionCriteriaData[i].expressions.value[0].inputType,
            conditionNameOperand:
              actionCriteriaData[i].expressions.value[0].inputExpression
                .operand,
          };
          actionCriteriaList.push(obj);
        }
      }
      setFormValues(actionCriteriaList);
      if (actionCriteriaList?.length < 1) {
        setFormValues([
          ...formValues,
          {
            conditionFilter: "",
            conditionName: "",
            conditionValue: "",
            unitName: "",
            switch: "List",
            conditionNameOperand: "",
          },
        ]);
      }
    } else {
      if (formValues?.length < 1) {
        setFormValues([
          ...formValues,
          {
            conditionFilter: "",
            conditionName: "",
            conditionValue: "",
            unitName: "",
            switch: "List",
            conditionNameOperand: "",
          },
        ]);
      }
    }
  }, []);
  console.log(formValues);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    let updatedFormValues = newFormValues.map((item) =>
      item.conditionFilter === "Change Placements %"
        ? { ...item, unitName: "%" }
        : item
    );
    setFormValues(updatedFormValues);

    let formData = {
      type: "thenData",
      thenData: newFormValues,
    };

    props.parentCallback(formData);
    props.wizardDataCallback(formData);
    wizardDataUpdate(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        conditionFilter: "",
        conditionName: "",
        conditionValue: "",
        unitName: "",
        target: "",
        switch: "List",
        conditionNameOperand: "",
      },
    ]);
  };
  let duplicateFormFields = (i) => {
    let newFormValues = [...formValues];
    let duplicateRow = {
      conditionFilter: newFormValues[i].conditionFilter,
      conditionName: newFormValues[i].conditionName,
      conditionValue: newFormValues[i].conditionValue,
      unitName: newFormValues[i].unitName,
      switch: newFormValues[i].switch,
      conditionNameOperand: newFormValues[i].conditionNameOperand,
    };
    newFormValues.push(duplicateRow);
    setFormValues(newFormValues);
    wizardDataUpdate();
  };
  const removeFormFields = async (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    wizardDataUpdate(newFormValues);
  };
  let switchFormFields = (i) => {
    let newFormValues = [...formValues];
    if (newFormValues[i]["switch"] === "List") {
      newFormValues[i]["switch"] = "Calculated";
    } else {
      newFormValues[i]["switch"] = "List";
    }
    setFormValues(newFormValues);
    // wizardDataUpdate(newFormValues);
  };
  let handleSubmit = (event) => {};
  const getIfCondtionAndSetActionUpTo = () => {
    let ifTarget = props.wizardData.ifWizard[0].target;
    // console.log("dp wizARD",wizardData);
    console.log("dp target", ifTarget);

    // if(props.rulesMetaData.targetCriteriaDetails.inculde(ifTarget)){

    // }
    //   setActionUpToCriteriaDetails(props.rulesMetaData.actionCriteriaDetails);
    // setNewActionUpToCriteriaDetails(
    //   props.rulesMetaData.actionCriteriaDetails
    // );
  };
  function wizardDataUpdate(formValues) {
    let actionCriteria = [];
    for (let i = 0; i < formValues?.length; i++) {
      let obj = {
        logicalAND: true,
        target: { name: formValues[i].target, value: formValues[i].target },
        expressions: {
          operand: formValues[i].conditionFilter,
          operator: formValues[i].conditionName,
          value: [
            {
              inputType: formValues[i].switch,
              inputExpression: {
                operand: formValues[i].conditionNameOperand,
                input:
                  formValues[i].conditionValue || formValues[i].conditionName,
              },
            },
          ],
          // Set unit based on the condition
          unit:
            formValues[i].conditionFilter === "Add/Move Targets"
              ? ""
              : formValues[i].unitName || "",
        },
      };

      actionCriteria.push(obj);
    }

    let formData = {
      type: "actionCriteria",
      actionCriteria: actionCriteria,
    };
    props.parentCallback(formData);
    props.wizardDataCallback(formData);
  }

  function buildConditionDropdownData(listData, metaDataList) {
    let result = metaDataList;
    if (listData && result) {
      for (let i = 0; i < listData?.length; i++) {
        listData[i]["id"] = i + 1;
        listData[i]["selected"] = false;
        let slectedConditionType =
          listData[i]["filterConditionType"].toLowerCase();
        let conditionTypeList = result[slectedConditionType];
        listData[i]["conditionTypes"] = conditionTypeList;
      }
      setNewActionUpToCriteriaDetails(listData);
    }
  }
  const SubSelectOptions = (props) => {
    let { index, eleData } = props;
    console.log(newActionUpToCriteriaDetails);
    if (eleData.conditionFilter) {
      let findIndex = newActionUpToCriteriaDetails.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      if (findIndex > -1) {
        let conditionData = newActionUpToCriteriaDetails[findIndex];
        let optionData = conditionData.possibleValues;
        if (optionData) {
          // SubSelectOptionss();
          if (optionData?.length > 0) {
            return optionData?.map((options, i) => (
              <option key={i} value={options}>
                <div>{options}</div>
              </option>
            ));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const SubSelectOptionss = (props) => {
    let { eleData } = props;

    if (eleData.conditionFilter) {
      let findIndex = newActionUpToCriteriaDetails.findIndex(
        (obj) => obj.name === eleData.conditionFilter
      );

      if (findIndex > -1) {
        let conditionData = newActionUpToCriteriaDetails[findIndex];
        let optionData = conditionData.target[0];
        if (optionData) {
          return optionData.applyTo.map((options, i) => (
            <option key={i} value={options}>
              <div>{options}</div>
            </option>
          ));
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const SubSelecttargetOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = newActionUpToCriteriaDetails.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      if (findIndex > -1) {
        let conditionData = newActionUpToCriteriaDetails[findIndex];
        let optionData = conditionData.target;
        if (optionData) {
          if (optionData?.length > 0) {
            return optionData?.map((options, i) => {
              console.log(options);
              return (
                <option key={i} value={options.name}>
                  {options.name}
                </option>
              );
            });
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const UnitSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = newActionUpToCriteriaDetails.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      if (findIndex > -1) {
        let conditionData = newActionUpToCriteriaDetails[findIndex];
        let optionData = conditionData.units;
        if (optionData) {
          if (optionData?.length > 0) {
            return optionData.map((options, i) => (
              <option key={i} value={options}>
                {options}
              </option>
            ));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const TargetSelectOptions = (props) => {
    let { index, eleData, target } = props;
    console.log("eleData:", eleData);
    console.log("newActionUpToCriteriaDetails:", newActionUpToCriteriaDetails);
    console.log("target:", target);

    console.log("newActionUpToCriteriaDetails:", eleData);
    if (eleData && eleData.conditionFilter && newActionUpToCriteriaDetails) {
      const findIndex = newActionUpToCriteriaDetails.findIndex(
        (obj) => obj.name === eleData.conditionFilter
      );

      if (findIndex > -1) {
        const conditionData = newActionUpToCriteriaDetails[findIndex];
        const targetData = conditionData?.target;
        if (targetData && targetData?.length > 0) {
          return targetData.map((options, i) => (
            <option key={i} value={options.name}>
              {options.name}
            </option>
          ));
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  console.log(formValues);
  return (
    <>
      <div className="wizard-step" style={{ flexGrow: "1" }}>
        <h5>Then</h5>
        {!metaDataLoading && !ruleDataLoading ? (
          <>
            {formValues.map((element, index) => (
              <div className="card">
                <Row>
                  <Col md={12} style={{ flexGrow: 1 }}>
                    <form>
                      <div className="form-row">
                        <div className="ele-move-box">
                          <img src={moveIcon} alt="" />
                        </div>
                        <div className="form-field-group">
                          <div className="width-80p">
                            <select
                              style={{
                                // width: "307px",
                                height: "40px",
                                top: "24px",
                                left: "60px",
                              }}
                              className="form-select  focus-border-green"
                              name="conditionFilter"
                              value={element.conditionFilter || ""}
                              onChange={(e) => {
                                handleChange(index, e);
                              }}
                            >
                              <option value="">--Select--</option>
                              {newActionUpToCriteriaDetails.map((list, i) => (
                                <option value={list.name} key={i}>
                                  {list.displayName}
                                </option>
                              ))}
                            </select>
                          </div>
                          {element.conditionFilter === "Negative Targets" ? (
                            <div className="multiply-sympol-box">
                              Set&nbsp;&nbsp;
                            </div>
                          ) : null}
                          {element.conditionFilter ===
                            "Change Bidding Strategy" && (
                            <div
                              className="multiply-sympol-box"
                              style={{ width: "175px" }}
                            >
                              Change to
                            </div>
                          )}
                          {element.conditionFilter ===
                            "Change Placements %" && (
                            <div className="form-field width-150p">
                              <select
                                className="form-select  focus-border-green "
                                name="conditionName"
                                value={element.conditionName || ""}
                                onChange={(e) => handleChange(index, e)}
                                style={{ marginLeft: "10px" }}
                              >
                                <option value="">--Select--</option>
                                <SubSelectOptions
                                  index={index}
                                  eleData={element}
                                />
                              </select>
                            </div>
                          )}
                          {element.conditionFilter !== "Change Placements %" ? (
                            <div className="form-field width-100p">
                              <select
                                style={{
                                  // width: "338px",
                                  height: "40px",
                                  top: "24px",
                                  marginLeft: "10px",
                                }}
                                className="form-select  focus-border-green"
                                name="conditionName"
                                value={element.conditionName || ""}
                                onChange={(e) => handleChange(index, e)}
                              >
                                <option value="">--Select--</option>
                                <SubSelectOptions
                                  index={index}
                                  eleData={element}
                                />
                              </select>
                            </div>
                          ) : null}
                          {element.conditionFilter === "Add/Move Targets" ? (
                            <div className="multiply-sympol-box">to</div>
                          ) : element.conditionFilter === "Change Status" ||
                            element.conditionFilter ===
                              "Change Bidding Strategy" ? null : element.conditionFilter ===
                            "Negative Targets" ? (
                            <div className="multiply-sympol-box">at</div>
                          ) : element.conditionFilter ===
                            "Change Placements %" ? (
                            <></>
                          ) : (
                            <div className="multiply-sympol-box">by</div>
                          )}
                          &nbsp;&nbsp;
                          {element.conditionFilter === "Change Status" ? (
                            <div className="form-field width-100p">
                              <select
                                style={{
                                  width: "411px",
                                  height: "40px",
                                  top: "24px",
                                  left: "737px",
                                }}
                                className="form-select  focus-border-green"
                                name="target"
                                value={element.target || ""}
                                onChange={(e) => handleChange(index, e)}
                              >
                                <option value="">--Selects--</option>

                                <option
                                  value={props?.wizardData?.ifWizard[0]?.target}
                                >
                                  {props?.wizardData?.ifWizard[0]?.target}
                                  
                                </option>
                                <option value ="Campaign">Campaign</option>
                              </select>
                            </div>
                          ) : null}
                          {element.conditionFilter !== "Change Status" &&
                            element.conditionFilter !==
                              "Change Bidding Strategy" && (
                              <>
                                {element.conditionFilter !==
                                  "Change Placements %" &&
                                element.conditionFilter !==
                                  "Negative Targets" ? (
                                  <>
                                    {element.conditionFilter == "Change Bid" ||
                                    element.conditionFilter ==
                                      "Change Default Bid" ? (
                                      <div className="form-field width-35p">
                                        <select
                                          style={{
                                            // width: "80px",
                                            height: "40px",
                                            top: "24px",
                                            left: "773px",
                                          }}
                                          className="form-select  focus-border-green"
                                          name="unitName"
                                          value={element.unitName || ""}
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        >
                                          <option value="">--select--</option>

                                          <UnitSelectOptions
                                            index={index}
                                            eleData={element}
                                          />
                                        </select>
                                        {/* {props?.isError?.then?.includes(
                                          index
                                        ) && (
                                          <span className="text-danger">
                                            *requried
                                          </span>
                                        )} */}
                                      </div>
                                    ) : (
                                      <div className="form-field width-100p">
                                        <select
                                          style={{
                                            // width: "80px",
                                            height: "40px",
                                            top: "24px",
                                            left: "773px",
                                          }}
                                          className="form-select  focus-border-green"
                                          name="unitName"
                                          value={element.unitName || ""}
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        >
                                          <option value="">--select--</option>

                                          <UnitSelectOptions
                                            index={index}
                                            eleData={element}
                                          />
                                        </select>
                                      </div>
                                    )}
                                  </>
                                ) : element.conditionFilter ===
                                  "Negative Targets" ? (
                                  <div className="form-field width-100p">
                                    <select
                                      className="form-select  focus-border-green"
                                      name="target"
                                      value={element.target || ""}
                                      onChange={(e) => handleChange(index, e)}
                                    >
                                      <option value="">--select--</option>
                                      <SubSelectOptionss
                                        index={index}
                                        eleData={element}
                                      />
                                    </select>
                                  </div>
                                ) : element.conditionFilter ===
                                  "Change Placements %" ? (
                                  <div className="form-field width-100p">
                                    <select
                                      className="form-select  focus-border-green"
                                      name="target"
                                      value={element.target || ""}
                                      onChange={(e) => handleChange(index, e)}
                                    >
                                      <option value="">--select--</option>
                                      <SubSelecttargetOptions
                                        index={index}
                                        eleData={element}
                                      />
                                    </select>
                                  </div>
                                ) : null}
                                {element.conditionFilter ===
                                "Change Placements %" ? (
                                  <div className="multiply-sympol-box">to</div>
                                ) : (
                                  <></>
                                )}
                                {element.switch === "Calculated" ? (
                                  <>
                                    <div className="form-field width-100 rule-switch-container">
                                      <div className="width-100p">
                                        <select
                                          className="form-select  focus-border-green width-100p"
                                          name="conditionNameOperand"
                                          value={
                                            element.conditionNameOperand || ""
                                          }
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          <SubSelectOptions
                                            index={index}
                                            eleData={element}
                                          />
                                        </select>
                                      </div>
                                      <div className="multiply-sympol-box">
                                        x
                                      </div>
                                      <div>
                                        <input
                                          type="text"
                                          placeholder="enter Value"
                                          style={{ textAlign: "center" }}
                                          name="conditionValue"
                                          className="form-control"
                                          value={element.conditionValue || ""}
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="form-field rule-switch-container ">
                                    {element.conditionFilter !==
                                      "Negative Targets" &&
                                    element.conditionFilter !==
                                      "Add/Move Targets" ? (
                                      <>
                                        &nbsp;&nbsp;
                                        <div style={{ position: "relative" }}>
                                          {element.conditionFilter ===
                                          "Change Placements %" ? (
                                            <div className="dollar-text-end">
                                              %
                                            </div>
                                          ) : null}
                                          {element.unitName === "$" &&
                                          element.conditionFilter !==
                                            "Change Placements %" ? (
                                            <div className="dollar-text">$</div>
                                          ) : element.unitName === "%" ? (
                                            <div className="dollar-text-end">
                                              %
                                            </div>
                                          ) : null}

                                          <input
                                            style={{
                                              width: "260px",
                                              // textAlign: "center",
                                              paddingLeft: "40px",
                                              height: "40px",
                                            }}
                                            type="text"
                                            placeholder={
                                              element.unitName === "%"
                                                ? "enter percentage"
                                                : "enter amount"
                                            }
                                            // placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enter amount"
                                            name="conditionValue"
                                            className="form-control"
                                            value={element.conditionValue || ""}
                                            onChange={(e) =>
                                              handleChange(index, e)
                                            }
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                        <div className="form-action a-3">
                          {/* <div
                            className="icon-box-white cursor"
                            onClick={() => switchFormFields(index)}
                          >
                            <img src={SwitchIcon} alt="" />
                          </div> */}
                          <div
                            className="icon-box-white cursor"
                            onClick={() => duplicateFormFields(index)}
                          >
                            <img src={DuplicateIcon} alt="" />
                          </div>
                          {index !== 0 && (
                            <div
                              className="icon-box-gray cursor"
                              onClick={() => removeFormFields(index)}
                            >
                              <img src={RemoveIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </Col>
                </Row>
              </div>
            ))}
          </>
        ) : (
          <div className="loading-container">
            <div className="loading-text">
              {/* <span className="logicon">
                <img src={LogoIcon} alt="logo-small"></img>
              </span> */}
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
          </div>
        )}
        <div>
          <button
            type="button"
            class="btn btn-link add-more-btn"
            onClick={() => addFormFields()}
          >
            <i class="circle-add-icon"></i> Add Row
          </button>
        </div>
      </div>
    </>
  );
}

export default AddRulesThen;
