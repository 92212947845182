import React, { useEffect, useState } from "react";
import Sidebar from "../../shared/Sidebar";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import { Container, Spinner } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import "./index.css";
import DashboardService from "../../services/DashboardService";
interface Props {
  children: React.ReactNode;
}

const DashboardLayout: React.FunctionComponent<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const handleRefresh = async () => {
    setIsRefreshing(true);
    await IsSyncCompleted();
  };
  const planHandler = () => {
    let plan: any = localStorage.getItem("planStatus");
    let planStatus = JSON.parse(plan);
    if (!planStatus) {
      return navigate(`/plan-expriy`);
    }
  };
  const IsSyncCompleted = async () => {
    try {
      if (!localStorage.getItem("userToken")) {
        return <Navigate to={"/sign-in"} />;
      }
      const lastSyncInfo = await DashboardService.getLastSyncData();
      if (
        lastSyncInfo &&
        lastSyncInfo.kdpSyncStatus &&
        lastSyncInfo.adsSyncStatus
      ) {
        setSyncCompleted(true);
      }
    } catch (error) {
      console.error("Error fetching Last Sync Status:", error);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    setIsRefreshing(true);
    IsSyncCompleted();
    planHandler();
  }, []);

  return (
    <Container className="admin-bg p-0 dashboard-layout" fluid>
      <div className="sidebar-main-container">
        <Sidebar />
      </div>
      <div className="maincont">
        <Header />
        {loading ? (
          <main>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                height: "100%",
                minHeight: "500px",
                marginTop: "140px",
              }}
              className="horizontalBarChart"
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <br></br>
              <div className="loading ms-4">Loading...</div>
            </div>
          </main>
        ) : syncCompleted ? (
          <main>{props.children}</main>
        ) : (
          <>
            <div className="pop-up">
              <div className="back-image">
                <div className="popup-border">
                  <div className="text-box">
                    <h1 className="pop-up-text">
                      <p className="syncmsg">
                        Your combined KDP and Ads data is syncing. You will
                        receive an email when it is completed. Thank you for
                        your patience!
                      </p>
                    </h1>
                    <button
                      className="sync-button"
                      onClick={handleRefresh}
                      disabled={isRefreshing}
                      style={{
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "21px",
                      }}
                    >
                      {isRefreshing ? "Refreshing..." : "Refresh"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <main>{props.children}</main>
          </>
        )}
        <Footer />
      </div>
    </Container>
  );
};
export default DashboardLayout;
