import BaseService from './BaseService';

class AmazonAdsService extends BaseService {
    static API_URL = '/AmazonAds';
    static cacheKeyPrefix_AuthorizationURL = 'AuthorizationURL_';
    static metaDataPromise: Promise<any> | null = null;

    static logErrorAndThrow(errorMessage: string, error: any): void {
        console.error(errorMessage, error);
        throw error;
    }

    static async Callback<T>(requestData = {}): Promise<any> {
        const mergedRequestData = { ...requestData };

        return await BaseService.handleRequestNoCache<T>(
            this.API_URL,
            mergedRequestData,
            'Callback',
            BaseService.post
        );
    }

    static async getGlobalFilterValueData<T>(region = ''): Promise<any> {
        const requestFunction = region ? BaseService.get : BaseService.getWithQueryParams;

        return await BaseService.handleRequestNoCache<T>(
            AmazonAdsService.API_URL,
            {
                Region: region
            },
            'AuthorizationURL',
            requestFunction
        );
    }
}


export default AmazonAdsService;
