/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Row, Col } from "react-bootstrap";
import "./index.css";

import { Link } from "react-router-dom";
import LinkIcon from "../../../assets/images/icons/link-icon.svg";
import BackArrow from "../../../assets/images/icons/back-arrow-icon.svg";

import AddRules from "./components/add-rule";
import AddNewRules from "./components/add-new-rule";

function CreateAutomationRules(props) {
  let { id } = useParams();
  const [showPanel, setShowPanel] = useState("Default");
  const lastUpdatedDate = localStorage.getItem("lastUpdatedDate") || null;

  useEffect(() => {
    if (id === "0") {
      setShowPanel("Default");
    } else {
      setShowPanel("AddNewRules");
    }
  }, []);

  const handleCallback = (loadPanelName) => {
    setShowPanel(loadPanelName);
  };
  return (
    <DashboardLayout>
      <div className="main-cont-header bookself-container">
        <Row className="page-header">
          <Col>
            <div className="main-con-page-title-container">
              <div className="back-arrow-container">
                <Link to={"/ads/automation-rules"}>
                  <i>
                    <img src={BackArrow} alt="refresh icon" />
                  </i>
                  Back
                </Link>
              </div>
            </div>
          </Col>
          {/* <Col className="text-end last-sync justify-content-end">
              <span
                  className="last-sync"
              >
                <div>Last App Sync</div>
                <div className="lastspan">
                    {" "}
                    {lastUpdatedDate || "Not available"}
                </div>
              </span>
            </Col> */}
        </Row>
      </div>
      <div className="main-content-container">
        <hr />
        <div className="dashboard-container padding-lr-30">
          {showPanel === "Default" && (
            <AddRules parentCallback={handleCallback} />
          )}
          {showPanel === "AddNewRules" && (
            <AddNewRules parentCallback={handleCallback} id={id} />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CreateAutomationRules;
