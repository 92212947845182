import React, { Component, RefObject } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Col, Row } from "react-bootstrap";
import Navbar from "../Navbar/navbar";
import "./amazonConnection.css";
import AmazonConnectionTable, {
  AmazonConnectionTableRef,
} from "../Table/amazonConnectionTable";
import { text } from "stream/consumers";
import { colors } from "@mui/material";
import { Link, redirect } from "react-router-dom";
let subscribe: string | null;

interface AmazonConnectionProps {}
subscribe = localStorage.getItem("planName");

interface AmazonConnectionState {
  amazonConnectionTableRef: RefObject<AmazonConnectionTableRef>;
}
export default class AmazonConnection extends Component<
  AmazonConnectionProps,
  AmazonConnectionState
> {
  constructor(props: AmazonConnectionProps) {
    super(props);
    this.state = {
      amazonConnectionTableRef: React.createRef<AmazonConnectionTableRef>(),
    };
    this.handlelocalStorage();
  }

  handleButtonClick = () => {
    console.log("Button clicked in AmazonConnection");
    this.state.amazonConnectionTableRef.current?.handleButtonClick();
  };
  handlelocalStorage = () => {
    subscribe = localStorage.getItem("planName");
  };
  render() {
    return (
      <DashboardLayout>
        <div className="amazon-conection mt-2 container-fluid">
          {subscribe === "FreePlan" && (
            <>
              <div className="sync-data-daily mb-2">
                <p className="syncmsg">
                  You are currently on a free plan. Upgrade to access additional
                  features and benefits...
                </p>
                <button
                  className="sync-button"
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                >
                  <Link
                    to={"/my-account/billing"}
                    style={{ textDecoration: "none" }}
                    className="upgradePlan"
                  >
                    Upgrade
                  </Link>
                </button>
              </div>
            </>
          )}

          <Row>
            <Col md={2}>
              <Navbar />
            </Col>
            <Col md={10}>
              <div className="amazonConnectionCard  p-4">
                <div className="amazonConectionHeading">
                  <h2>Amazon Connection</h2>
                  {/* <button
                    className="btn btn-primary"
                    onClick={this.handleButtonClick}
                  >
                    Add Merchant
                  </button> */}
                </div>
                <Row>
                  <Col md={12}>
                    <AmazonConnectionTable
                      ref={this.state.amazonConnectionTableRef}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </DashboardLayout>
    );
  }
}
