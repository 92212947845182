import React, { Component } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Col, Row } from "react-bootstrap";
import Navbar from "../Navbar/navbar";
import "./notification.css";
import { log } from "console";
import LogoIcon from "../../../assets/brand/logo-icon.svg";

interface NotificationState {
  notificationCategories: any[];
  isLoading: boolean;
  error?: string | null;
}

export default class Notification extends Component<{}, NotificationState> {
  state: NotificationState = {
    notificationCategories: [],
    isLoading: true,
    error: null,
  };

  componentDidMount() {
    this.fetchNotificationData();
  }

  fetchNotificationData = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    try {
      const response = await fetch("https://api.aimosa.io/Users/GetNotifications",{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        
        this.setState({ notificationCategories: data.result.notification.notification_categories, isLoading: false });
      } else {
        throw new Error("Failed to fetch notification data");
      }
    } catch (error) {
      console.log(error);
    }
  };

 
  handleToggleEnable = async (categoryIndex: number, itemIndex: number) => {
    try {
      const { notificationCategories } = this.state;
      const updatedCategories = [...notificationCategories];
      updatedCategories[categoryIndex].items[itemIndex].isEnabled = !updatedCategories[categoryIndex].items[itemIndex].isEnabled;

      const requestBody = JSON.stringify({
        name: "Notification",
        notification: { notification_categories: updatedCategories },
      });
      const userToken = localStorage.getItem("userToken");
      if (!userToken) throw new Error("User token not found.");

      const AuthToken = "Bearer " + userToken;

      const response = await fetch("https://api.aimosa.io/Users/Notification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: requestBody,
      });

      if (response.ok) {
        this.setState({ notificationCategories: updatedCategories });
      } else {
        throw new Error("Failed to save notification data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { notificationCategories, isLoading, error } = this.state;

    return (
      <DashboardLayout>
        <div className="container-fluid my-account-notification">
          <Row>
            <Col md={2}>
              <Navbar />
            </Col>
            <Col md={10}>
              <div className="container notificationCard">
                <Col md={12}>
                  <h2>Notification</h2>
                  <div className="row notificationContainer">
                    {isLoading ? (
                      <div className="loading-container">
                        <div className="loading-text">
                          {/* <span className="logicon">
                            <img src={LogoIcon} alt="logo-small"></img>
                          </span> */}
                          <span>L</span>
                          <span>O</span>
                          <span>A</span>
                          <span>D</span>
                          <span>I</span>
                          <span>N</span>
                          <span>G</span>
                        </div>
                      </div>
                    ) : error ? (
                      <p>{error}</p>
                    ) : notificationCategories && notificationCategories.length > 0 ? (
                      notificationCategories.map((category, categoryIndex) => (
                        <div key={categoryIndex} className={`col-md-6 ${category.name.toLowerCase()}Notification`}>
                          <div className="type-email">
                            <h6>{category.name}</h6>
                            <p>{category.description}</p>
                          </div>
                          {category.items.map((item: any, itemIndex: number) => (
                            <div key={itemIndex} className="emailBodyContent">
                              <div className="form-check form-switch">
                              <input
                               className="form-check-input"
                               type="checkbox"
                               role="switch"
                               id={`flexSwitchCheckChecked-${categoryIndex}-${itemIndex}`}
                               checked={item.isEnabled}
                               onChange={() => this.handleToggleEnable(categoryIndex, itemIndex)}
                              />
                                <label className="form-check-label"></label>
                              </div>
                              <div className="rightContent">
                                <h6>{item.name}</h6>
                                <p>{item.description}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <p>No notification data available</p>
                    )}
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </DashboardLayout>
    );
  }
}