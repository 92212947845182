import BaseService from './BaseService';

const userToken = localStorage.getItem('userToken');

class DashboardService extends BaseService {
    static API_URL = '/Dashboard';

    static cacheKeyPrefix_AuthorsData = `authors_data_${userToken}_`;
    static cacheKeyPrefix_BestSellerData = `best_seller_data_${userToken}_`;
    static cacheKeyPrefix_BooksData = `books_data_${userToken}_`;
    static cacheKeyPrefix_ChartData = `chart_data_${userToken}_`;
    static cacheKeyPrefix_DonutChartData = `donut_chart_data_${userToken}_`;
    static cacheKeyPrefix_FunnelChart = `funnel_chart_data_${userToken}_`;
    static cacheKeyPrefix_GlobalFilterValueData = `global_filter_value_data_${userToken}_`;
    static cacheKeyPrefix_HeatMapData = `heatmap_data_${userToken}_`;
    static cacheKeyPrefix_HorizontalChartData = `horizontal_chart_data_${userToken}_`;
    static cacheKeyPrefix_LastSyncData = `last_sync_data_${userToken}_`;
    static cacheKeyPrefix_MarketplacesData = `marketplaces_data_${userToken}_`;
    static cacheKeyPrefix_MetricsData = `metrics_data_${userToken}_`;
    static cacheKeyPrefix_WorldMapChartData = `world_map_chart_data_${userToken}_`;


    static async getAuthorsData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData
        };
        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_AuthorsData,
            DashboardService.API_URL,
            mergedRequestData,
            'Authors',
            BaseService.post
        );
    }

    static async getBestSellerData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = { ...requestData };

        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_BestSellerData,
            DashboardService.API_URL,
            mergedRequestData,
            'BestSeller',
            BaseService.post
        );
    }

    static async getBooksData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData,
        };

        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_BooksData,
            DashboardService.API_URL,
            mergedRequestData,
            'Books',
            BaseService.post
        );
    }

    static async getChartData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData,
        };

        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_ChartData,
            DashboardService.API_URL,
            mergedRequestData,
            'ChartData',
            BaseService.post
        );
    }


    static async getDonutChartData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData,
        };

        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_DonutChartData,
            DashboardService.API_URL,
            mergedRequestData,
            'DonutChart',
            BaseService.post
        );
    }

    static async getFunnelChartData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            category: 'Marketplace',
            categoryType: '',
            ...requestData,
        };
        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_FunnelChart,
            DashboardService.API_URL,
            mergedRequestData,
            'FunnelChart',
            BaseService.post
        );
    }

    static async getGlobalFilterValueData<T>(searchText = ''): Promise<any> {
        const requestFunction = searchText ? BaseService.get : BaseService.getWithQueryParams;

        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_GlobalFilterValueData,
            DashboardService.API_URL,
            { searchText: searchText },
            'GlobalFilterValue',
            requestFunction
        );
    }

    static async getHeatMapData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            category: 'Marketplace',
            ...requestData,
        };
        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_HeatMapData,
            DashboardService.API_URL,
            mergedRequestData,
            'HeatMap',
            BaseService.post
        );
    }

    static async getHorizontalChartData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            categories: 'Marketplace',
            metrics: 'Spend',
            ...requestData,
        };
        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_HorizontalChartData,
            DashboardService.API_URL,
            mergedRequestData,
            'HorizontalChart',
            BaseService.post
        );
    }

    static async getLastSyncData<T>(): Promise<any> {
        const url = `LastSync`.replace(/\/+/g, '/');
        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_LastSyncData,
            DashboardService.API_URL,
            undefined,
            url,
            BaseService.get
        );
    }


    static async getMarketplacesData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            pageNumber: 1,
            pageSize: 10,
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            ...requestData,
        };

        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_MarketplacesData,
            DashboardService.API_URL,
            mergedRequestData,
            'Marketplaces',
            BaseService.post
        );
    }

    static async getMetricsData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            metricName: '',
            ...requestData,
        };

        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_MetricsData,
            DashboardService.API_URL,
            mergedRequestData,
            'Metrics',
            BaseService.post
        );
    }

    static async getWorldMapChartData<T>(requestData = {}): Promise<any> {
        const mergedRequestData = {
            globalFilters: {
                dateRanges: {
                    dateRange: 'Custom',
                    ...BaseService.calculateDateRange(),
                },
            },
            "metrics": "Spend",
            ...requestData,
        };

        return await BaseService.handleRequest<T>(
            DashboardService.cacheKeyPrefix_WorldMapChartData,
            DashboardService.API_URL,
            mergedRequestData,
            'WorldMapChart',
            BaseService.post
        );
    }

}

export default DashboardService;


