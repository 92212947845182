import React, { Component } from "react";
import "./index.css";
import OnOffIcon from "../../assets/images/icons/on-off-icon.svg";
import BellIcon from "../../assets/images/icons/bell-icon.svg";
import GroupMemberIcon from "../../assets/images/icons/group-member-icon.svg";
import ProfileIcon from "../../assets/images/icons/profile-icon.svg";
import ActiveIcon from "../../assets/images/icons/acive-green-icon.svg";
import ProfileDropIcon from "../../assets/images/icons/profile-down-arrow.svg";
import upgradetoproImage from "../../assets/images/icons/upgradetopro.jpg";
import addaccount from "../../assets/images/icons/addaccount.png";
import logouticon from "../../assets/images/icons/download (1).png";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";

class Header extends Component {
  state = {
    userNames: localStorage.getItem("userName"),
    userImage: localStorage.getItem("userimg"),
    userName: "",
  };

  componentDidMount() {
    this.getProfile();
  }

  getProfile = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;

    try {
      const response = await fetch("https://api.aimosa.io/Users/Me", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      });

      const responseData = await response.json();
      localStorage.setItem(
        "currencyCode",
        responseData.result.currencyTypeSelection ?? "US"
      );
      console.log(
        "currencyCode",
        responseData.result.currencyTypeSelection ?? "US"
      );
      this.setState({
        userName: responseData.result.userName,
        userImage: responseData.result.image,
      });
      console.log("User Name:", responseData.result.userName);
      localStorage.setItem("userimg", responseData.result.image);
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };
  logoutHandler = () => {
    localStorage.removeItem("userimg");
  };

  render() {
    const { userImage } = this.state;

    return (
      <Navbar expand="lg" className="bg-body-tertiary page-top-bar">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          {/* <form className="header-search">
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
            <input
              type="text"
              placeholder="Search tutorial, post, community, etc..."
              name="search"
            />
          </form> */}
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            <Nav className="justify-content-end">
              <Nav.Link
                href="#action1"
                className="mx-4 custom-switch-container"
              ></Nav.Link>
              <div className="vr mx-4"></div>
              <Nav.Link href="#action2" className="ms-2">
                <img src={BellIcon} alt="" />
              </Nav.Link>
              <Nav.Link href="#" className="mx-2">
                <img src={GroupMemberIcon} alt="" />
              </Nav.Link>
              <div className="vr mx-4"></div>
              <NavDropdown
                className="ms-4"
                title={
                  <div>
                    <div className="profile-icon d-flex align-items-center">
                      <div className="profile-img">
                        <div className="profile-icon d-flex align-items-center">
                          {userImage ? (
                            <img
                              src={userImage}
                              alt="profile pic"
                              style={{
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                objectFit: "cover",
                              }}
                              loading="lazy"
                              width={30}
                              height={30}
                            />
                          ) : (
                            <div className="skeleton-loading"></div>
                          )}
                        </div>
                        {userImage && (
                          <i>
                            <img src={ActiveIcon} alt="active icon" />
                          </i>
                        )}
                      </div>
                      <span className="profile-userName">
                        {this.state.userName}
                      </span>

                      <span>
                        <img
                          src={ProfileDropIcon}
                          alt="drop icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            top: "2px",
                            left: "2px",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                }
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item href="/my-account/profile">
                  <div
                    className="myProfileLogo"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontFamily: "Plus Jakarta Sans",
                      height: "21px",
                      color: " #374151",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    <i
                      className="profile-logo"
                      style={{
                        color: "#6B7280",
                        width: "20px",
                        height: "20px",
                        top: "2px",
                        left: "2px",
                        border: "1.5px",
                      }}
                    />
                    My Profile
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />

                <NavDropdown.Item href="" className="upgrade-to-pro-text">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "11px",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "var(--baseColor)",
                      fontFamily: "Plus Jakarta Sans",
                    }}
                  >
                    <img
                      src={upgradetoproImage}
                      style={{
                        width: "21px",
                        height: "21px",
                        top: "1.5px",
                        left: "1.5px",
                        borderRadius: "16px",
                      }}
                      alt="Upgrade to Pro"
                    />
                    Upgrade To PRO
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />

                <NavDropdown.Item href="/my-account/billing">
                  <div
                    className="bill"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Plus Jakarta Sans",
                      gap: "10px",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#374151",
                    }}
                  >
                    <i
                      className="profileBilling-logo"
                      style={{
                        width: "13.74px",
                        height: "17.64px",
                        top: "3.43px",
                        left: "3.43px",
                        border: "1.5px",
                        color: " #6B7280",
                      }}
                    />
                    Billing & Plan
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />
                <NavDropdown.Item href="/my-account/notification">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Plus Jakarta Sans",
                      gap: "10px",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#374151",
                    }}
                  >
                    {" "}
                    <i
                      className="profileNotification-logo"
                      style={{
                        width: "15.26px",
                        height: "15.09px",
                        top: "3.43px",
                        left: "4,37px",
                        border: "1.5px",
                        color: "#6B7280",
                      }}
                    />
                    Notification
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />
                <NavDropdown.Item href="/my-account/amazon-connection">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Plus Jakarta Sans",
                      gap: "8px",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#374151",
                    }}
                  >
                    <i
                      className="amazonConnection-logo"
                      style={{
                        height: "24px",
                        width: "24px",
                        color: "#6B7280",
                      }}
                    />
                    Amazon Connection
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />
                <NavDropdown.Item href="" className="littleSpace">
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      fontFamily: "Plus Jakarta Sans",
                      lineHeight: "21px",
                      color: "#374151",
                    }}
                  >
                    <img
                      src={addaccount}
                      style={{
                        width: "20px",
                        height: "20px",
                        top: "2px",
                        left: "2px",
                        border: "1.5px",
                        color: "#6B7280",
                      }}
                      alt="addaccount"
                    />
                    Add Account
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />
                <NavDropdown.Item
                  href="/sign-in"
                  className="redColor"
                  onClick={this.logoutHandler}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontFamily: "Plus Jakarta Sans",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#D4111E",
                    }}
                  >
                    <img
                      src={logouticon}
                      style={{ width: "35px", marginLeft: "-12px" }}
                      alt="addaccount"
                    />
                    Logout
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
