import React, { useState, useRef, useEffect } from "react";
import "./DynamicWidthDropdown.css";
import InfoButton from "../../Common/InfoButton";

const DynamicWidthDropdown = ({
  defaultMetric,
  options,
  selectedItem,
  handleSelectChange,
  defaultText = "Select",
}) => {
  const [dropdownWidth, setDropdownWidth] = useState("");
  const [selectedItemData, setSelectedItemData] = useState({});
  const [isDefaultMetric, setIsDefaultMetric] = useState({
    Budget: "",
    Cpc: "",
    Orders: "",
    Sales: "",
  });
  const [isInput, setIsInput] = useState({
    Budget: "",
    Cpc: "CPC",
    Orders: "Orders",
    Sales: "Sales",
  });
  const dropdownRef = useRef(null);

  useEffect(() => {
    updateDropdownWidth();
  }, []);

  useEffect(() => {
    updateDropdownWidth();
  }, [selectedItem]);
  const updateDropdownWidth = () => {
    if (dropdownRef.current && selectedItem) {
      // Get the width of the selected item element
      const foundItem = options?.find((option) => option.name === selectedItem);
      if (foundItem) {
        setSelectedItemData(foundItem);
        dropdownRef.current.textContent = `${foundItem.title}`;
      }

      const selectedItemWidth = dropdownRef.current.offsetWidth;
      // Set the dropdown width to the width of the selected item + some padding
      setDropdownWidth(`${selectedItemWidth + 21}px`);
    }
  };
  const changeHandler = (defaultMetric, e) => {
    setIsInput((prev) => {
      if (defaultMetric === "Budget") {
        return {
          ...prev,
          Budget: e.target.value,
        };
      } else if (defaultMetric === "CPC") {
        return {
          ...prev,
          Cpc: selectedItemData.title,
        };
      }
      return prev;
    });
  };
  //   console.log(isInput);
  return (
    <div className="DynamicWidthDropdown">
      <select
        className="form-select widget-select"
        ref={dropdownRef}
        value={selectedItem}
        onClick={(event) => changeHandler(defaultMetric, event)}
        onChange={(event) =>
          handleSelectChange(event, event.target.selectedIndex, defaultMetric)
        }
        style={{ width: dropdownWidth }}
      >
        <option value="" disabled>
          {defaultText}
        </option>
        {options?.map((option, index) => {
          //   console.log(index);
          return (
            <option
              title={option.description}
              key={option.name}
              value={option.name}
            >
              {option.title}{" "}
              {option.subTitle ? `${"\u00A0"}[${option.subTitle}]` : ""}
            </option>
          );
        })}
      </select>
      <span className="gray-text-m">
        {selectedItemData && selectedItemData.subTitle && (
          <>[{selectedItemData.subTitle}]</>
        )}
      </span>
      <InfoButton infoText={selectedItemData.description} />
      <span
        ref={dropdownRef}
        style={{
          visibility: "hidden",
          position: "absolute",
          whiteSpace: "nowrap",
        }}
      ></span>
    </div>
  );
};

export default DynamicWidthDropdown;
