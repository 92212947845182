class CacheService {
    static cache = {};
    static cacheExpiry = 5 * 60 * 1000;
    static updateCache(key, value) {
        this.cache[key] = {
            data: value,
            timestamp: Date.now(),
        };
    }

    static getFromCache(key) {
        const cachedData = this.cache[key];
        return cachedData && (Date.now() - cachedData.timestamp) < this.cacheExpiry
            ? cachedData.data
            : null;
    }
}

export default CacheService;
