import BaseService from './BaseService';
import CacheService from './CacheService';

class MasterDataService extends BaseService {
    static API_URL = '/MasterData';
    static cacheKey = 'metaData';
    static metaDataPromise: Promise<any> | null = null;

    static logErrorAndThrow(errorMessage: string, error: any): void {
        console.error(errorMessage, error);
        throw error;
    }

    static async getMetaData<T>(requestData = {}): Promise<any> {
        try {
            const cacheKey = MasterDataService.cacheKey;

            // Check if data is in the cache and not expired
            const cachedData = CacheService.getFromCache(cacheKey);
            if (cachedData) {
                //console.log('Data found in cache:', cachedData);
                return cachedData;
            }

            // If there's already a request in progress, wait for it to complete
            if (MasterDataService.metaDataPromise) {
                //console.log('Waiting for the previous request to complete...');
                await MasterDataService.metaDataPromise;
                // Data should be in the cache now, so no need to fetch again
                return CacheService.getFromCache(cacheKey);
            }

            const url = `${MasterDataService.API_URL}/meta`;

            // Create a new promise for the current request
            MasterDataService.metaDataPromise = BaseService.get(url)
                .then((response) => handleMetaDataResponse<T>(response, cacheKey))
                .catch((error) => {
                    MasterDataService.logErrorAndThrow('Error fetching last sync data:', error);
                })
                .finally(() => {
                    // Reset the promise when the request is complete
                    MasterDataService.metaDataPromise = null;
                });

            // Wait for the promise to settle (resolve or reject)
            return await MasterDataService.metaDataPromise;
        } catch (error) {
            MasterDataService.logErrorAndThrow('Error fetching last sync data:', error);
        }
    }
}

function handleMetaDataResponse<T>(response: any, cacheKey: string): T {
    if (response.success) {
        CacheService.updateCache(cacheKey, response.result.data);
        return response.result as T;
    } else {
        MasterDataService.logErrorAndThrow('API error:', response.message);
        throw new Error('API error');
    }
}

export default MasterDataService;
